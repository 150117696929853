import React from 'react'
import API from '../../api'
import {Link, withRouter} from 'react-router-dom'
import Moment from 'react-moment';
import ReactSafeHtml from 'react-safe-html'
import Layout from '../Layout'
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel, Utils} from "../../_helpers/Loader";
import {Context} from "../MyContext";
import {PAYSTACK_publicKey, MONNIFY_contractCode, MONNIFY_APIKey} from "../../env";
import Fade from 'react-reveal/Fade';
import uuidv1 from 'uuid';
import PdfViewer from '../account/fileViewer/pdfViewer';

import $ from 'jquery';

const PaystackPop = window.PaystackPop;

class MarketPlaceDetails extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            investment: {},
            interestedInvestment: {},
            page: 1,
            units: '',
            total: '',
            payment_type: 'bank',
            bank_ref_code: '',
            user: {},
            balance: '',
            completion_rate: '',
            comments: [],
            review_msg: '',
            review_page: 1,
            number_of_pages: 0,
            animate: false,
            not_eligible: false,
            reply_text: '',
            fraud: false,
            bank_transfer_details: {},
            fund_options: ['real estate fund', 'money market fund', 'investment fund', 'real estate'],
            comment_question: '',
            club: {},
            member_unit_percentage: null
        };

        this.detailForm = this.detailForm.bind(this);
        this.onChangeUnit = this.onChangeUnit.bind(this);
        this.bankAction = this.bankAction.bind(this);
        this.walletAction = this.walletAction.bind(this);
        this.onBack = this.onBack.bind(this);
        this.payWithCard = this.payWithCard.bind(this);
        // this.confirmBank = this.confirmBank.bind(this);
        this.lessBalance = this.lessBalance.bind(this);
        this.payWithMonnify = this.payWithMonnify.bind(this);
    }

    async componentDidMount() {
        this.setState({user: this.context.user});
        trackMixpanel('ClubMarketplaceDetailView', false, false, this.context.user.email)
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
        let getClub = this.context.clubs.find(item => item.id == this.context.club_id)
        this.setState({club: getClub})
        this.setState({not_eligible: getClub.club_account_info.length == 0 ? true : false });
        showLoader();
        await this.getInvestment();
        await this.getInterestedInvestment();
        await this.getWalletInfo();
        await this.getReviews();
        hideLoader();
    }

    componentWillMount() {
        if (this.context.user.completed_onboarding === null || this.context.user.completed_onboarding === undefined) {
            window.location.reload();
        }
        if (this.context.user.completed_onboarding === false) {
            return this.props.history.push("/user/onboarding");
        }
    }

    async getInvestment() {
        try {
            let res = await API.get(`investments/${this.props.match.params.market_id}/`);
            const init_completion_rate = ((res.data.total_units_sold / res.data.units) * 100).toFixed(0);
            const completion_rate = (init_completion_rate > 100) ? 100 : init_completion_rate;
            this.setState({investment: res.data, completion_rate: completion_rate})
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.repsonse)
        }
    }

    async getInterestedInvestment() {
        try {
            let res = await API.get(`show_club_interest/get_interested_club/?investment_id=${this.props.match.params.market_id}&club_id=${this.props.match.params.id}`);
            console.log(res,`show_club_interest/get_interested_club/?investment_id=${this.props.match.params.market_id}&club_id=${this.props.match.params.id}`)
            // const init_completion_rate = ((res.data.total_units_sold / res.data.units) * 100).toFixed(0);
            // const completion_rate = (init_completion_rate > 100) ? 100 : init_completion_rate;
            if (res.data.length == 0 && this.checkAdmin()){
                // redirect the user to the marketplace
                // toast.error("Contact your club admin to indicate interest first and alot the number of units.")
                // this.props.history.push(`/club/${this.props.match.params.id}/marketplace`)
                this.setState({interestedInvestment: []})
            } else if (res.data.length > 0 && this.checkAdmin()){
                //redirect the admin to the club dashboard
                toast.error("You already indicated interest as a club, go to the club dashboard to modify the units or indicate interest as a member")
                this.setState({interestedInvestment: res.data[0]})
                // this.props.history.push(`/club/${this.props.match.params.id}/details`)
            } else{
                //for member to show interest
                this.setState({interestedInvestment: res.data[0]})
                console.log(this.state.interestedInvestment, "interestedInvestment>>>")
            }
        } catch (e) {
            toast.error("Something Went Wrong");
        }
    }

    createReview = async (event) => {
        showLoader();
        event.preventDefault();
        let postData = {
            body: this.state.review_msg
        };
        try {
            let res = await API.post(`investments/${this.props.match.params.id}/make_review/`, postData);
            this.setState({review_msg: ''});
            this.getReviews();
            var el = document.getElementById('reviews');
            el.scrollIntoView();
            var height = el.style.clientHeight;
            window.scrollBy(0, height - window.innerHeight);
            hideLoader();
        } catch (e) {
            // console.log(e.response)
        }
    };

    getReviews = async () => {
        try {
            let res = await API.get(`investments/${this.props.match.params.id}/get_reviews/`, {params: {page: this.state.review_page}});
            this.setState({comments: res.data.reviews, number_of_pages: res.data.pages})
        } catch (e) {
            // console.log(e)
        }
    };

    getMoreReview = async (page) => {
        try {
            let res = await API.get(`investments/${this.props.match.params.id}/get_reviews/`, {params: {page: page}});
            this.setState({
                comments: res.data.reviews,
                number_of_pages: res.data.pages,
                review_page: page,
            });
        } catch (e) {
            // console.log(e)
        }
    };

    // async confirmBank() {
    //     let postData = {
    //         investment: this.state.investment.id,
    //         ref_code: this.state.bank_ref_code,
    //         amount: this.state.total,
    //         purchased_units: parseFloat(this.state.units),
    //         roi: parseFloat(this.state.investment.inv_roi),
    //     };
    //     showLoader();
    //     try {
    //         let res = await API.post('my_investments/', postData);
    //         toast.success("Your payment request has been successfully submitted");
    //         await setTimeout(() => this.props.history.push("/"), 2000);
    //     } catch (e) {
    //         hideLoader();
    //         // console.log(e.response)
    //     }
    // }

    async getWalletInfo() {
        try {
            let res = await API.get(`club_wallet/${this.props.match.params.id}/get_my_wallet/`);
            this.setState({balance: (res.data.length > 0) ? res.data[0].current_balance : 0})
        } catch (e) {
            toast.error("Something Went Wrong");
            // console.log(e.response)
        }
    }

    //club pay for invesment
    async walletAction() {
        let reference = "wallet-vest" + Math.floor((Math.random() * 1000000000) + 1);
        let postData = {
            amount: this.currencyToNGN(this.state.investment.currency),
            txRef: reference,
            phone: this.context.user.phone,
            id: this.state.investment.id,
            units: this.state.units,
            wallet: true,
            club_id: this.context.club_id,
            pay_type: 'wallet'
        };
        showLoader();
        try {
            let res = await API.put('investments/' + this.state.investment.id + '/confirm_payment/', postData);
            toast.success("Successfully Completed Transaction");
            document.getElementById('close-modal').click();
            $('.modal-backdrop').remove();
            this.props.history.push(`/club/${this.context.club_id}/investments`)
        } catch (e) {
            if (e.response.status === 400 && e.response.data) {
                toast.error("Insufficient Funds. Kindly Fund your Wallet");
                hideLoader();
                setTimeout(() => {
                    document.getElementById('close-modal').click();
                    $('.modal-backdrop').remove();
                    this.props.history.push('/account/wallet')
                }, 2000)
            } else {
                toast.error("Something went wrong");
            }
            // console.log(e.response);
            hideLoader();
        }
    }

    detailForm(event) {
        event.preventDefault();
        const {unit} = event.target;
        this.setState({page: this.state.page + 1, animate: true})
    }

    onChangeUnit(event) {
        console.log(event.target.value, this.state.investment.status, event.target.value * this.state.investment.unit_price)
        if (event.target.value < 1) {
            this.setState({units: '', total: ''});
        } else {
            this.setState({units: event.target.value, total: event.target.value * this.state.investment.unit_price});
            console.log(this.state.interestedInvestment.proposed_unit, "ooo")
            this.setState({ member_unit_percentage: (event.target.value / this.state.interestedInvestment.proposed_unit) * 100})
        }
    }

    onBack() {
        this.setState({page: this.state.page - 1})
    }

    createdArray(num) {
        let array_obj = [];
        for (var i = 1; i <= num; i++) {
            array_obj.push(i)
        }
        return array_obj
    }

    async bankAction(event) {
        let code = "TVEST" + Math.floor((Math.random() * 100000) + 1);
        showLoader();
        this.setState({
            page: this.state.page + 1,
            payment_type: 'bank',
            bank_ref_code: code
        });
        hideLoader();
    }

    successTransaction = () => {
        window.location.href = "/manage-portfolio";
    };

    async payWithMonnify() {
        let postData = {
            investment: this.state.investment.id,
            purchased_units: parseFloat(this.state.units),
            payment_type: 'monnify_invoice',
            club_id: this.context.club_id
        };
        showLoader();
        try {
            let res = await API.post('club_investments/', postData);
            // console.log(res.data);
            this.setState({bank_transfer_details: res.data});
            hideLoader();
            $('#bankModal').modal('show');
        } catch (e) {
            hideLoader();
        }
    }

    redirect = () => {
        toast.info("Kindly update your club information,including account number first");
        setTimeout(() => {
            this.props.history.push({
                pathname: `/manage-club/${this.context.club_id}`,
                query: {back_url: this.props.location.pathname}
            })
        }, 3000);
    };

    replyComment = async (event, id) => {
        event.preventDefault();
        let postData = {
            body: ($(`#reply-text-${id}`).val()).replace(/^\s+|\s+$/gm, ''),
            id: id
        };
        try {
            let res = await API.post(`investments/${this.props.match.params.id}/make_reply/`, postData);
            $(`#reply-text-${id}`).val('');
            this.getReviews();
        } catch (e) {
            // console.log(e.response)
        }
    };

    likeComment = async (event, id, el, type_comment) => {
        event.preventDefault();
        let postData = {
            id: id,
            type_comment
        };
        showLoader();
        try {
            let res = await API.post(`investments/${this.props.match.params.id}/make_like/`, postData);
            $(`#reply-text-${id}`).val('');
            this.getReviews();
            hideLoader()
        } catch (e) {
            hideLoader()
            // console.log(e.response)
        }
    };

    checkAdmin() {
        let clubs = this.context.clubs;
        let club = clubs.filter(item => item.id == this.context.club_id)[0];
        let item = club.club_admins;
        if (item !== undefined) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].user === this.context.user.id) {
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    currencyToNGN(currency) {
        let club_tag = this.state.investment.club_tag ? true : false
        if (!club_tag) {
            if (currency === "USD") {
                return parseFloat(this.state.total) * parseFloat(this.context.user.usd)
            } else if (currency === "GBP") {
                return parseFloat(this.state.total) * parseFloat(this.context.user.gbp)
            } else {
                parseFloat(this.state.total)
            }
        } else {
            if (currency === 'USD') {
                return parseFloat(this.context.user.usd) * parseFloat(this.state.total / this.state.investment.maturity_cycle)
            } else if (currency === "GBP") {
                return parseFloat(this.context.user.gbp) * parseFloat(this.state.total / this.state.investment.maturity_cycle)
            } else {
                return parseFloat(this.state.total / this.state.investment.maturity_cycle)
            }
        }
    }

    async payFromWalletInterested() {
        let reference = "interested-vest" + Math.floor((Math.random() * 1000000000) + 1);
        let postData = {
            amount: this.currencyToNGN(this.state.investment.currency),
            txRef: reference,
            phone: this.context.user.phone,
            id: this.state.investment.id,
            units: this.state.units,
            wallet: true,
            club_id: this.context.club_id,
            pay_type: 'wallet'
        };
        console.log("hfjsdfjds" , JSON.stringify(postData))
        showLoader();
        try {
            let res = await API.post('/show_club_interest/' + this.state.investment.id +'/member_show_interest/', postData);
            console.log(res.data, "0000000000000000000")
            toast.success("Successfully Completed Transaction");
            document.getElementById('close-modal').click();
            $('.modal-backdrop').remove();
            this.props.history.push(`/club/${this.context.club_id}/details`)
            hideLoader()
        } catch (e) {
            if (e.response.status === 400 && e.response.data) {
                if (e.response.data.details == "Insufficient fund in your wallet"){
                    toast.error("Insufficient Funds. Kindly Fund your Wallet");
                    hideLoader();
                    setTimeout(() => {
                        document.getElementById('close-modal').click();
                        $('.modal-backdrop').remove();
                        this.props.history.push('/account/wallet')
                    }, 2000)
                } else if(e.response.data.details){
                    	toast.error(e.response.data.details)
                }
            } else {
                toast.error("Something went wrong");
            }
            // console.log(e.response);
            hideLoader();
        }
    }

    
    async showInterest(){
        showLoader();
        let postData = {
            investment: this.state.investment.id,
            question: this.state.comment_question,
            proposed_unit: this.state.units,
            club: this.context.club_id,
        }
        console.log(postData, "postData")
        
        try {
            let res = await API.post('show_club_interest/', postData);
            $('.close').click();
            $('.modal-backdrop').remove();
            console.log(this.state, "this.state")
            toast.success("Operation Successful")
            this.props.history.push(`/club/${this.context.club_id}/marketplace`)
            hideLoader();
        } catch (e) {
            toast.error("Operation Failed")
            hideLoader();
        }

    }


    detail() {
        return (
            <Layout active_menu={'market'} context={this.context}>
                <Fade right={(!this.state.animate) ? false : true}>
                    <div className="section">
                        <div className="breadcrumb">
                            <Link to={`/club/${this.context.club_id}/marketplace`}><span
                                className="fa fa-chevron-left"/> Offers</Link>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-7 col-xl-7">
                                <div className="card">
                                    <div className="img-container mb-4 full-block">
                                        <img className="img-crop-md" src={this.state.investment.image} alt=""/>
                                        <div className="text-overlay-left-bottom text-left">
                                            {this.state.investment.in_trust ? (
                                                <i className="fas fa-shield-alt text-dark"/>) : ''}
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <ReactSafeHtml html={this.state.investment.description}/>
                                                <br/>
                                                <p className="font-weight-bold">
                                                    {this.state.investment.in_trust ? `This investment is held in Trust by ${this.state.investment.trustee}` : ''}
                                                </p>
                                                <br/>
                                                {this.state.investment?.due_deligence ? 
                                                <button className="btn btn-lg btn-primary text-white btn-blue" data-target="#dueDeligence"
                                                data-toggle="modal" onClick={() => this.setState({pdf: this.state.investment?.due_deligence})} >View Due Diligence Report</button> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-5 col-xl-5">
                                <div className="card">
                                    <div className="card-header bg-green">
                                        <strong>{this.state.investment.title} by {this.state.investment.company}</strong>
                                    </div>
                                    <div className="card-body">
                                        <dl className="row">
                                            {this.state.fund_options.includes(this.state.investment.investment_type) ? '' : (
                                                <React.Fragment>
                                                    <dt className="col-sm-4 text-right"><strong>Trade Cycle</strong>
                                                    </dt>
                                                    <dd className="col-sm-8">{this.state.investment.trade_cycle}</dd>
                                                </React.Fragment>
                                            )}
                                            <dt className="col-sm-4 text-right"><strong>Tenor</strong></dt>
                                            <dd className="col-sm-8">{this.state.investment.maturity_cycle} Months</dd>

                                            <dt className="col-sm-4 text-right"><strong>ROI</strong></dt>
                                            <dd className="col-sm-8">{this.state.investment.inv_roi}% {this.state.investment.tenor_type == "p.a" ? "Per Annum" : "Flat"}</dd>

                                            <dt className="col-sm-4 text-right"><strong>Per Unit</strong></dt>
                                            <dd className="col-sm-8">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.investment.unit_price).toLocaleString()}</dd>

                                            <dt className="col-sm-4 text-right"><strong>Ends on</strong></dt>
                                            <dd className="col-sm-8"><Moment
                                                format="MM-DD-YYYY">{this.state.investment.closing_date}</Moment></dd>
                                        </dl>
                                        <div className="row">
                                            {(this.state.total && this.state.investment.club_tag) ? (
                                                <div className="col-sm-12 mx-auto mb-3 text-orange font-italic">
                                                    Note: This investment is paid
                                                    in {this.state.investment.maturity_cycle} installments at
                                                    NGN{parseFloat(this.state.total / this.state.investment.maturity_cycle).toLocaleString()} per
                                                    month.
                                                    You will have the opportunity to review transaction details before
                                                    making a
                                                    payment.
                                                </div>) : ''
                                            }
                                            <div className="col-sm-12">
                                                <h4 className="text-left">How
                                                    many {this.state.fund_options.includes(this.state.investment.investment_type) ? 'tokens' : 'units'} will
                                                    you like to purchase?</h4>
                                            </div>
                                        </div>
                                        <form onSubmit={this.detailForm} autoComplete="off">
                                            <div className="">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="units">{this.state.fund_options.includes(this.state.investment.investment_type) ? 'Token' : 'Unit'}</label>
                                                    <input min="1" onChange={(e) =>  this.onChangeUnit(e) } defaultValue={this.state.units}
                                                           type="number" className="form-control" id="units"
                                                           placeholder="Enter Units"/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="amount">Total Cost</label>
                                                    <input readOnly style={{color: 'black'}} disabled
                                                           value={(this.state.total) ? `${Utils.currencyToSym(this.state.investment.currency)}${parseFloat(this.state.total).toLocaleString()}` : ''}
                                                           type="text" className="form-control" id="amount"/>
                                                </div>
                                                {this.state.investment.currency !== "NGN" ? (
                                                    <div className="form-group">
                                                        <label htmlFor="amount">Total Cost
                                                            ({Utils.currencyToSym(this.state.investment.currency)})</label>
                                                        <input readOnly style={{color: 'black'}}
                                                               value={(this.state.total) ? `₦${this.currencyToNGN(this.state.investment.currency).toLocaleString()}` : ''}
                                                               type="text" className="form-control" id="amount"/>
                                                    </div>
                                                ) : ''}
                                                <div className="form-group">
                                                    <label htmlFor="amount">Total Earnings</label>
                                                    <input aria-disabled disabled
                                                           value={(this.state.total) ? `${Utils.currencyToSym(this.state.investment.currency)}${((parseFloat(this.state.total) * parseFloat(this.state.investment.inv_roi) / 100) + parseFloat(this.state.total)).toLocaleString()}` : ''}
                                                           type="text" className="form-control" id="amount"/>
                                                </div>
                                                {(this.state.investment && this.state.investment.status && this.state.investment.status.toLowerCase() == "coming soon" && (Object.keys(this.state.interestedInvestment).length > 0) && this.state.member_unit_percentage) ? (<div>
                                                You are buying {this.state.member_unit_percentage.toFixed(2)}% of Cocoa farm by Agro Allied Company
                                                </div>) : ""}
                                            </div>
                                            
                                            {(this.state.units > 0 && this.state.not_eligible) ? (
                                                <button type="button" onClick={event => this.redirect()}
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">Update Profile
                                                </button>
                                            ) : (this.state.total >= this.state.investment.unit_price) ? ((!['active', 'coming soon'].includes(this.state.investment.status.toLowerCase()) || this.state.completion_rate >= 100) ? (
                                                <button disabled type="button"
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">Investment Closed
                                                </button>
                                            ) : ((this.checkAdmin()) ? (
                                                // (this.state.investment.club_tag) ? (
                                                //     <button disabled type="button"
                                                //             className="btn btn-blue text-white mt-1 mb-0 btn-block">Coming soon
                                                //     </button>
                                                // ) : 
                                                
                                                (this.state.investment && this.state.investment.status && this.state.investment.status.toLowerCase()) === "coming soon" ? (Object.keys(this.state.interestedInvestment).length > 0 ? (
                                                    <button type="submit" onClick={(e) => {
                                                            e.preventDefault(); 
                                                            console.log("fsdfdsfsdfs")
                                                            $('#memberShowInterestModal').modal('show');
                                                        }}
                                                                className="btn btn-blue text-white mt-1 mb-0 btn-block">{"Buy investment as a member of"} {this.state.club.name}  
                                                        </button>
                                                ):(<button type="submit" onClick={(e) => {
                                                    e.preventDefault(); 
                                                    console.log("fsdfdsfsdfs")
                                                    $('#showInterestModal').modal('show');
                                                }}
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">{"Show Interest in investment as an admin of"} {this.state.club.name}  
                                                </button>)
                                                         ):(<button type="submit"
                                                                className="btn btn-blue text-white mt-1 mb-0 btn-block">Continue
                                                        </button>
                                                    )
                                                ) : (this.state.investment.status.toLowerCase() === "coming soon" ? <button type="submit" onClick={(e) => {
                                                    e.preventDefault(); 
                                                    console.log("fsdfdsfsdfs")
                                                    if (!this.checkAdmin()){
                                                        
                                                    }
                                                    $('#memberShowInterestModal').modal('show');
                                                }}
                                                        className="btn btn-blue text-white mt-1 mb-0 btn-block">{"Buy investment as a member of "} {this.state.club.name}
                                                </button> :<h4>Kindly contact your Club Admin</h4>)
                                            )) : ''}
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-md-12 col-sm-12 col-lg-7 col-xl-7">*/}
                            {/*    <div className="card">*/}
                            {/*        <div className="card-header bg-green">*/}
                            {/*            <strong>Reviews</strong>*/}
                            {/*        </div>*/}
                            {/*        <div className="card-body">*/}
                            {/*            <div className="row">*/}
                            {/*                <div className="col-md-12">*/}
                            {/*                    <div className="status-upload mt-2 mb-4 d-inline-block w-100">*/}
                            {/*                        <form onSubmit={this.createReview}>*/}
                            {/*                            {(this.state.comments.length < 1) ? (*/}
                            {/*                                <div className="mb-3">*/}
                            {/*                                    <strong>No reviews. <br/>*/}
                            {/*                                        Be the first to write a review on this*/}
                            {/*                                        investment</strong>*/}
                            {/*                                </div>*/}
                            {/*                            ) : ''}*/}
                            {/*                            <textarea value={this.state.review_msg} rows="5"*/}
                            {/*                                      maxLength="5000"*/}
                            {/*                                      required*/}
                            {/*                                      onChange={event => this.setState({review_msg: event.target.value})}*/}
                            {/*                                      className="form-control"*/}
                            {/*                                      placeholder="Add review here"/>*/}

                            {/*                            <div className="pull-left">*/}
                            {/*                                <small>{5000 - this.state.review_msg.length} Words Max*/}
                            {/*                                </small>*/}
                            {/*                            </div>*/}
                            {/*                            <button type="submit"*/}
                            {/*                                    className="btn btn-blue text-white btn-sm pull-right mt-2"> Post*/}
                            {/*                            </button>*/}
                            {/*                        </form>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="col-md-12" id="reviews">*/}
                            {/*                    {this.state.comments.map(item => (*/}
                            {/*                        <div key={item.id}>*/}
                            {/*                            <div className="media mt-0">*/}
                            {/*                                <div className="media-left"><img*/}
                            {/*                                    src={(item.user.profile_photo) ? item.user.profile_photo : 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'}*/}
                            {/*                                    alt=""*/}
                            {/*                                    className="media-object"/></div>*/}
                            {/*                                <div className="media-body">*/}
                            {/*                                    <h4 className="media-heading text-dark">{item.user.first_name} {item.user.last_name} {' '}*/}
                            {/*                                        <i className="fa fa-certificate text-green"/> Verified<br/>*/}
                            {/*                                        <small className="text-muted"><span*/}
                            {/*                                            className="fa fa-clock"/> <Moment*/}
                            {/*                                            format="LLLL">{item.modified_at}</Moment>*/}
                            {/*                                        </small>*/}
                            {/*                                    </h4>*/}
                            {/*                                    <p className="text-justify text-main">{item.body}</p>*/}
                            {/*                                    <ul className="nav nav-pills pull-left">*/}
                            {/*                                        <li><a id={`likeNum-${item.id}`} href="#"*/}
                            {/*                                               onClick={event => this.likeComment(event, item.id, $(`#likeNum-${item.id}`), 1)}><span*/}
                            {/*                                            className="fa fa-thumbs-up"></span> {(item.likes_num > 0) ? item.likes_num : ''}*/}
                            {/*                                        </a></li>*/}
                            {/*                                        <li><a href="" onClick={event => {*/}
                            {/*                                            event.preventDefault();*/}
                            {/*                                            $(`#replybox-${item.id}`).toggleClass("d-none");*/}
                            {/*                                        }}><span*/}
                            {/*                                            className="fa fa-share"></span> {(item.reply.length > 0) ? item.reply.length : ''}*/}
                            {/*                                        </a></li>*/}
                            {/*                                    </ul>*/}
                            {/*                                    <br/>*/}
                            {/*                                    {(item.reply.length > 0) ?*/}
                            {/*                                        <div>*/}
                            {/*                                            {item.reply.map(reply => (*/}
                            {/*                                                <div className="media mb-2">*/}
                            {/*                                                    <div className="media-left"><a*/}
                            {/*                                                        href="javascript:void(0)"> <img*/}
                            {/*                                                        alt="64x64"*/}
                            {/*                                                        src={(reply.user.profile_photo) ? reply.user.profile_photo : 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'}*/}
                            {/*                                                        className="media-object"/>*/}
                            {/*                                                    </a></div>*/}
                            {/*                                                    <div className="media-body">*/}
                            {/*                                                        <h4 className="media-heading">{reply.user.first_name} {reply.user.last_name} {' '}*/}
                            {/*                                                            <span*/}
                            {/*                                                                className="fa fa-user-cog"/>*/}
                            {/*                                                        </h4>*/}
                            {/*                                                        <p>{reply.body}</p>*/}
                            {/*                                                        <ul className="nav nav-pills pull-left">*/}
                            {/*                                                            <li><a href="#"*/}
                            {/*                                                                   onClick={event => this.likeComment(event, item.id, $(`#likeNum-${item.id}`), 2)}*/}
                            {/*                                                            ><span*/}
                            {/*                                                                className="fa fa-thumbs-up"></span> {(reply.likes_num > 0) ? reply.likes_num : ''}*/}
                            {/*                                                            </a>*/}
                            {/*                                                            </li>*/}
                            {/*                                                            <li><a href="#"*/}
                            {/*                                                                   onClick={event => {*/}
                            {/*                                                                       event.preventDefault();*/}
                            {/*                                                                       $(`#replybox-${item.id}`).toggleClass("d-none");*/}
                            {/*                                                                   }}><span*/}
                            {/*                                                                className="fa fa-share"></span>*/}
                            {/*                                                            </a></li>*/}
                            {/*                                                        </ul>*/}
                            {/*                                                    </div>*/}
                            {/*                                                </div>*/}
                            {/*                                            ))}*/}
                            {/*                                        </div>*/}
                            {/*                                        : ''}*/}
                            {/*                                    <div className="col-md-12 d-none"*/}
                            {/*                                         id={`replybox-${item.id}`}>*/}
                            {/*                                        <div*/}
                            {/*                                            className="status-upload mt-2 mb-3 d-inline-block w-100">*/}
                            {/*                                            <form*/}
                            {/*                                                onSubmit={event => this.replyComment(event, item.id)}>*/}
                            {/*                                                <textarea id={`reply-text-${item.id}`}*/}
                            {/*                                                          className="form-control"*/}
                            {/*                                                          placeholder="Comment here"></textarea>*/}
                            {/*                                                <br/>*/}
                            {/*                                                <button type="submit"*/}
                            {/*                                                        className="btn btn-blue text-white btn-sm pull-right"> Reply*/}
                            {/*                                                </button>*/}
                            {/*                                            </form>*/}
                            {/*                                        </div>*/}
                            {/*                                    </div>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                            <hr/>*/}
                            {/*                        </div>*/}
                            {/*                    ))}*/}
                            {/*                    {(this.state.number_of_pages > 1) ? (*/}
                            {/*                        <div className="row">*/}
                            {/*                            <div className="dataTables_paginate paging_simple_numbers"*/}
                            {/*                                 id="example_paginate">*/}
                            {/*                                <ul className="pagination">*/}
                            {/*                                    {(this.state.review_page > 1 && this.state.number_of_pages > 1) ? (*/}
                            {/*                                        <li onClick={event => this.getMoreReview(this.state.review_page - 1)}*/}
                            {/*                                            className="paginate_button page-item previous"*/}
                            {/*                                            id="example_previous"><a*/}
                            {/*                                            aria-controls="example"*/}
                            {/*                                            data-dt-idx="0"*/}
                            {/*                                            tabIndex="0"*/}
                            {/*                                            className="page-link">Previous</a>*/}
                            {/*                                        </li>*/}
                            {/*                                    ) : ''}*/}
                            {/*                                    {this.createdArray(this.state.number_of_pages).map(item => (*/}
                            {/*                                        <li onClick={event => this.getMoreReview(item)}*/}
                            {/*                                            className={`paginate_button page-item ${(this.state.review_page === item) ? 'active' : ''}`}>*/}
                            {/*                                            <a aria-controls="example"*/}
                            {/*                                               data-dt-idx="1"*/}
                            {/*                                               tabIndex="0"*/}
                            {/*                                               className="page-link">{item}</a>*/}
                            {/*                                        </li>*/}
                            {/*                                    ))}*/}
                            {/*                                    {(this.state.number_of_pages > 1 && this.state.review_page < this.state.number_of_pages) ? (*/}
                            {/*                                        <li onClick={event => this.getMoreReview(this.state.review_page + 1)}*/}
                            {/*                                            className="paginate_button page-item next"*/}
                            {/*                                            id="example_next"><a aria-controls="example"*/}
                            {/*                                                                 data-dt-idx="6"*/}
                            {/*                                                                 tabIndex="0"*/}
                            {/*                                                                 className="page-link">Next</a>*/}
                            {/*                                        </li>*/}
                            {/*                                    ) : ''}*/}
                            {/*                                </ul>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="col-12 text-center">*/}
                            {/*                                {(!this.state.review_page_end) ? ((this.state.comments.length > 0) ? (*/}
                            {/*                                        <button type="button"*/}
                            {/*                                                onClick={this.getMoreReview}*/}
                            {/*                                                className="btn btn-blue text-white text-white text-center">Load*/}
                            {/*                                            more*/}
                            {/*                                        </button>) : ('')*/}
                            {/*                                ) : (*/}
                            {/*                                    <p>No more comments.</p>*/}
                            {/*                                )}*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    ) : ''}*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="modal fade" id="dueDeligence" tabindex="-1" role="dialog"
                     aria-labelledby="dueDeligenceTitle" aria-hidden="true">
                        <div className="modal-dialog  modal-lg" role="document" style={{"max-width": "1200px", "width": "100%"}}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="dueDeligenceTitle">Deligence Report</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <PdfViewer pdf={this.state.investment?.due_deligence} back={null} />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="showInterestModal" tabIndex="-1" role="dialog" aria-labelledby="showInterestModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-green text-center">
                                    <h4 className="modal-title" id="showInterestModalLabel">Do you have any Comment or Question</h4>
                                    <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                            aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <dl className="row">
                                                <dt className="col-sm-12 text-left">Comment / Question</dt>
                                                <dd className="col-sm-12">
                                                    <div className="form-group">
                                                        <textarea className="form-control" placeholder="Comment/Question" name="comment_question" rows="4" onChange={(e) => {
                                                        this.setState({
                                                            comment_question: e.target.value
                                                        })
                                                        }}></textarea>
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-blue text-white text-white" data-dismiss="modal">Close
                                    </button>
                                    <button onClick={event => {
                                        this.showInterest(event);
                                    }} type="button"
                                            className="btn btn-blue text-white">Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="memberShowInterestModal" tabIndex="-1" role="dialog" aria-labelledby="memberShowInterestModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-green text-center">
                                    <h4 className="modal-title" id="memberShowInterestModalLabel">Are you sure</h4>
                                    <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                            aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    NGN {this.state.total.toLocaleString()} will be debited from your wallet, for a credit to the Club wallet.
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-blue text-white text-white" data-dismiss="modal">Close
                                    </button>
                                    <button onClick={event => {
                                        this.payFromWalletInterested(event);
                                    }} type="button"
                                            className="btn btn-blue text-white">Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Layout>
        )
    }

    lessBalance() {
        toast.info("Insufficient fund.");
        setTimeout(() => {
            toast.info("Add money to wallet.");
        }, 1000);
        setTimeout(() => {
            this.props.history.push({
                pathname: '/account/wallet',
                query: {back_url: this.props.location.pathname}
            })
        }, 2000);
    }

    payWithCardRequest = async () => {
        let postData = {
            investment: this.state.investment.id,
            purchased_units: parseFloat(this.state.units),
            payment_type: 'monnify_invoice',
            club_id: this.context.club_id
        };
        showLoader();
        try {
            let res = await API.post('club_investments/', postData);
            // console.log(res.data);
            this.setState({bank_transfer_details: res.data});
            hideLoader();
            $('#bankModal').modal('show');
        } catch (e) {
            hideLoader();
        }
    }

    payWithCard = () => {
        const script = document.createElement("script");

        script.src = "https://js.paystack.co/v1/inline.js";
        script.async = true;

        let ref_start = this.state.investment.club_tag ? 'club-paystack-estate' : 'club-paystack-vest';

        document.body.appendChild(script);
        let amount = this.state.investment.club_tag ? parseFloat(Utils.charges(parseFloat(this.state.total / this.state.investment.maturity_cycle).toFixed(2))) : parseFloat(this.state.total);
        let main_amount = this.currencyToNGN(this.state.investment.currency)
        console.log(amount, main_amount)
        var handler = PaystackPop.setup({
            key: PAYSTACK_publicKey,
            email: this.state.user.email,
            amount: main_amount * 100,
            currency: "NGN",
            ref: ref_start + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
            metadata: {
                custom_fields: [
                    {
                        display_name: "First Name",
                        variable_name: "firstname",
                        value: this.state.user.first_name,
                    }, {
                        display_name: "Last Name",
                        variable_name: "lastname",
                        value: this.state.user.last_name,
                    }, {
                        display_name: "Customer Number",
                        variable_name: "customer_phone",
                        value: this.state.user.phone,
                    }, {
                        display_name: "Investment",
                        variable_name: "investment_title",
                        value: `${this.state.investment.title} by ${this.state.investment.company}`
                    },
                ]
            },
            callback: (response) => {
                response.amount = this.state.total;
                response.txRef = response.reference;
                response.phone = this.state.user.phone;
                response.id = this.state.investment.id;
                response.units = this.state.units;
                response.pay_type = 'paystack';
                response.club_id = this.context.club_id;
                showLoader();
                if (
                    response.status === "success"
                ) {
                    // redirect to a success page
                    API.put('investments/' + this.state.investment.id + '/confirm_payment/', response).then((res) => {
                        hideLoader();
                        toast.success("Successfully Completed Transaction");
                        this.props.history.push(`/club/${this.context.club_id}/investments`)
                    }, function (err) {
                        hideLoader();
                        toast.error("Something Went Wrong")
                    })

                } else {
                    // redirect to a failure page.
                    hideLoader();
                    toast.error("Payment Error.")
                }
                //alert('success. transaction ref is ' + response.reference);
            },
            onClose: function () {
                toast.info("Payment Cancelled");
                hideLoader();
            }
        });
        handler.openIframe();
    };

    walletBalance() {
        if (this.state.investment.club_tag) {
            if (this.state.investment.currency === "NGN") {
                return (
                    <p className="mb-0">Balance after transaction:
                        ₦{parseFloat(parseFloat(this.state.balance - (parseFloat(this.state.total) / parseFloat(this.state.investment.maturity_cycle))).toFixed(2)).toLocaleString()}</p>
                )
            } else if (this.state.investment.currency === "USD") {
                return (
                    <p className="mb-0">Balance after transaction:
                        ₦{parseFloat(parseFloat(this.state.balance - ((parseFloat(this.state.total) * parseFloat(this.context.user.usd)) / parseFloat(this.state.investment.maturity_cycle))).toFixed(2)).toLocaleString()}</p>
                )
            } else if (this.state.investment.currency === "GBP") {
                return (
                    <p className="mb-0">Balance after transaction:
                        ₦{parseFloat(parseFloat(this.state.balance - ((parseFloat(this.state.total) * parseFloat(this.context.user.gbp)) / parseFloat(this.state.investment.maturity_cycle))).toFixed(2)).toLocaleString()}</p>
                )
            }
        } else {
            if (this.state.investment.currency === "USD") {
                return (
                    <p className="mb-0">Balance after transaction:
                        ₦{parseFloat((this.state.balance - (this.state.total * parseFloat(this.context.user.usd))).toFixed(2)).toLocaleString()}</p>
                )
            } else if (this.state.investment.currency === "GBP") {
                return (
                    <p className="mb-0">Balance after transaction:
                        ₦{parseFloat((this.state.balance - (this.state.total * parseFloat(this.context.user.gbp))).toFixed(2)).toLocaleString()}</p>
                )
            } else {
                return (
                    <p className="mb-0">Balance after transaction:
                        ₦{parseFloat(this.state.balance - this.state.total).toLocaleString()}</p>
                )
            }
        }
    }

    summary() {
        return (
            <Layout active_menu={'market'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <a onClick={this.onBack}><span className="fa fa-chevron-left"></span> Back</a>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-4">
                                        <div className="col-sm-12">
                                            <h4 className="text-center">Transaction Summary</h4>
                                            <p className="text-center">{this.state.investment.title} by {this.state.investment.company}</p>
                                        </div>

                                        <div className="col-sm-12">
                                            {(this.state.total && this.state.investment.club_tag) ? (
                                                <div className="col-sm-12 mx-auto mb-3 text-orange font-italic">
                                                    Note: This investment is paid
                                                    in {this.state.investment.maturity_cycle} installments at
                                                    NGN{parseFloat(this.state.total / this.state.investment.maturity_cycle).toLocaleString()} per
                                                    month.
                                                    You will have the opportunity to review transaction details
                                                    before
                                                    making a
                                                    payment.
                                                </div>) : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-9 mx-auto">
                                            <dl className="row mx-auto">
                                                <dt className="col-sm-6 text-right">
                                                    <strong>{this.state.fund_options.includes(this.state.investment.investment_type) ? 'Token' : 'Unit'} Price</strong>
                                                </dt>
                                                <dd className="col-sm-6">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.investment.unit_price).toLocaleString()}</dd>

                                                <dt className="col-sm-6 text-right">
                                                    <strong>Purchasing {this.state.fund_options.includes(this.state.investment.investment_type) ? 'token' : 'unit'}</strong>
                                                </dt>
                                                <dd className="col-sm-6">{this.state.units}</dd>

                                                <dt className="col-sm-6 text-right"><strong>Total</strong></dt>
                                                <dd className="col-sm-6">{Utils.currencyToSym(this.state.investment.currency)}{parseFloat(this.state.total).toLocaleString()}</dd>

                                                <dt className="col-sm-6 text-right"><strong>Amount To Pay</strong>
                                                </dt>
                                                <dd className="col-sm-6">{Utils.currencyToSym(this.state.investment.currency)}
                                                    {
                                                        (this.state.investment.club_tag) ?
                                                            parseFloat(parseFloat(this.state.total / this.state.investment.maturity_cycle).toFixed(2)).toLocaleString() :
                                                            parseFloat(this.state.total).toLocaleString()
                                                    }
                                                </dd>
                                                {this.state.investment.currency !== "NGN" ? (
                                                    <React.Fragment>
                                                        <dt className="col-sm-6 text-right"><strong>Amount To Pay
                                                            (₦)</strong>
                                                        </dt>
                                                        <dd className="col-sm-6">₦
                                                            {
                                                                (this.state.investment.club_tag) ?
                                                                    parseFloat(this.currencyToNGN(this.state.investment.currency, true)).toFixed(2).toLocaleString() :
                                                                    (this.currencyToNGN(this.state.investment.currency)).toLocaleString()
                                                            }
                                                        </dd>
                                                    </React.Fragment>
                                                ) : ''}
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-sm-12">
                                            <div className="form-check">
                                                <input onChange={event => this.setState({fraud: !this.state.fraud})}
                                                       className="form-check-input" type="checkbox"
                                                       checked={this.state.fraud}
                                                       id="defaultCheck1"/>
                                                <label className="form-check-label" htmlFor="defaultCheck1"><strong>
                                                    <sup className="text-danger">*</sup> Tick to confirm you are not
                                                    involved in money laundering to
                                                    continue.</strong>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {(this.state.investment.card_payment) ? (
                                                <button disabled={!this.state.fraud}
                                                        onClick={event => this.payWithCard()}
                                                        className="btn btn-blue text-white btn-lg btn-block"><i
                                                    className="fa fa-credit-card"/> Pay with Card
                                                </button>
                                            ) : ''}
                                            {/* {(this.state.investment.transfer_payment) ? (
                                                <button disabled={!this.state.fraud}
                                                        onClick={event => this.payWithMonnify()}
                                                        className="btn btn-blue text-white btn-lg btn-block"><i
                                                    className="fa fa-bank"/> Pay with
                                                    Bank Transfer
                                                </button>
                                            ) : ''} */}
                                            {(this.state.investment.wallet_payment) ? ((this.state.balance >= this.state.total) ? (
                                                <button disabled={!this.state.fraud} data-toggle="modal"
                                                        data-target="#walletModal"
                                                        className="btn btn-blue text-white btn-lg btn-block"><i
                                                    className="fa fa-wallet"/> Pay with
                                                    Wallet
                                                </button>
                                            ) : (
                                                <button disabled={!this.state.fraud} data-toggle="modal"
                                                        data-target="#walletModal"
                                                        onClick={event => this.lessBalance}
                                                        className="btn btn-blue text-white btn-lg btn-block"><i
                                                    className="fa fa-wallet"/> Pay with
                                                    Wallet
                                                </button>
                                            )) : ''}
                                            {(this.state.investment.club_tag) ? (
                                                <small className="text-orange">Note: Transactions charges may
                                                    apply</small>
                                            ) : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="walletModal" tabIndex="-1" role="dialog"
                     aria-labelledby="walletModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green">
                                <h5 className="modal-title" id="exampleModalLabel">Confirm wallet transaction</h5>
                                <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="mb-0">Wallet balance:
                                    ₦{parseFloat(this.state.balance).toLocaleString()}</p>
                                {this.walletBalance()}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-green mr-3" data-dismiss="modal">Close
                                </button>
                                {(this.state.investment.club_tag) ?
                                    ((this.state.balance - parseFloat(this.state.total) / parseFloat(this.state.investment.maturity_cycle)) >= 0) ? (
                                        <button onClick={event => this.walletAction(event)} type="button"
                                                className="btn btn-main"><i
                                            className="fas fa-wallet"/> Pay with wallet</button>
                                    ) : (
                                        <button disabled onClick={event => this.walletAction(event)} type="button"
                                                className="btn btn-main"><i
                                            className="fas fa-wallet"/> Pay with wallet</button>
                                        // <button onClick={event => {
                                        //     document.getElementById('close-modal').click();
                                        //     $('.modal-backdrop').remove();
                                        //     this.props.history.push("/account/wallet")
                                        // }}
                                        //         type="button" className="btn btn-main"><i
                                        //     className="fas fa-wallet"/> Fund wallet</button>
                                    )
                                    : ((this.state.balance - this.state.total) >= 0) ? (
                                        <button onClick={event => this.walletAction(event)} type="button"
                                                className="btn btn-main"><i
                                            className="fas fa-wallet"/> Pay with wallet</button>
                                    ) : (
                                        <button disabled onClick={event => this.walletAction(event)} type="button"
                                                className="btn btn-main"><i
                                            className="fas fa-wallet"/> Pay with wallet</button>
                                        // <button onClick={event => {
                                        //     document.getElementById('close-modal').click();
                                        //     $('.modal-backdrop').remove();
                                        //     this.props.history.push("/account/wallet")
                                        // }}
                                        //         type="button" className="btn btn-main"><i
                                        //     className="fas fa-credit-card"/> Fund wallet</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="bankModal" tabIndex="-1" role="dialog"
                     aria-labelledby="bankModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-green text-center">
                                <h4 className="modal-title" id="bankModalLabel">Bank Transfer Details</h4>
                                <button id="close-modal" type="button" className="close" data-dismiss="modal"
                                        aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <dl className="row">
                                            <dt className="col-sm-6 text-right">Account Name</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.investment.title} by {this.state.investment.company}</strong>
                                            </dd>
                                            <dt className="col-sm-6 text-right">Account Number</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.bank_transfer_details.monnify_account_number}</strong>
                                            </dd>
                                            <dt className="col-sm-6 text-right">Bank</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.bank_transfer_details.monnify_account_bank}</strong>
                                            </dd>
                                            <dt className="col-sm-6 text-right">Expiry Datetime</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.bank_transfer_details.monnify_expiry_date}</strong>
                                            </dd>
                                            <dt className="col-sm-6 text-right">Invoice Number</dt>
                                            <dd className="col-sm-6">
                                                <strong>{this.state.bank_transfer_details.monnify_account_name}</strong>
                                            </dd>
                                        </dl>
                                        <p>Once payment is confirmed, you will get a notification about the completion
                                            of your
                                            purchase.</p>
                                        <p>Invoice is only valid before the expiry date and time.</p>
                                        <p>You can always create a new request, if you need to.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-blue text-white text-white" data-dismiss="modal">Close
                                </button>
                                <button onClick={event => {
                                    $('.close').click();
                                    $('.modal-backdrop').remove();
                                    this.props.history.push(`/club/${this.props.match.params.id}/investments`)
                                }} type="button"
                                        className="btn btn-blue text-white">Check Portfolio
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    payWithBank() {
        return (
            <Layout active_menu={'market'} user_state={'personal'} context={this.context}>
                <div className="section">
                    <div className="breadcrumb">
                        <a onClick={this.onBack}><span className="fa fa-chevron-left"/> Back</a>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h4 className="text-center">Transaction Summary</h4>
                                            <p className="text-center">{this.state.investment.title} by {this.state.investment.company}</p>
                                            <hr style={{border: '2px solid #23414D'}}/>
                                        </div>

                                        <div className="col-sm-12">
                                            {(this.state.total && this.state.investment.club_tag) ? (
                                                <div className="col-sm-12 mx-auto mb-3 text-orange font-italic">
                                                    Note: This investment is paid in
                                                    this.state.investment.maturity_cycle installments at
                                                    NGN{parseFloat(this.state.total / this.state.investment.maturity_cycle).toLocaleString()} per
                                                    month.
                                                    You will have the opportunity to review transaction details before
                                                    making a
                                                    payment.
                                                </div>) : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p className="text-center"><i className="text-dark">Please transfer funds to
                                                the account number below with the following
                                                code: <span
                                                    className="bg-orange text-main" style={{
                                                    fontSize: '20px',
                                                    padding: '4px',
                                                    paddingRight: '8px',
                                                    fontStyle: 'initial'
                                                }}>{this.state.bank_ref_code} </span></i>
                                            </p>
                                            <dl className="row">
                                                <dt style={{fontWeight: 100}} className="col-sm-6 text-right">Account
                                                    Name
                                                </dt>
                                                <dd className="col-sm-6"><strong>Twelvevest</strong></dd>
                                                <dt className="col-sm-6 text-right">Account Number</dt>
                                                <dd className="col-sm-6"><strong>0825927041</strong></dd>
                                                <dt className="col-sm-6 text-right">Bank</dt>
                                                <dd className="col-sm-6"><strong>Access Bank</strong></dd>
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12" onClick={this.confirmBank}>
                                            <button className="btn btn-blue text-white btn-block">Click to confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    render() {
        if (this.state.page === 1) {
            return this.detail()
        } else if (this.state.page === 2) {
            return this.summary()
        } else if (this.state.page === 3 && this.state.payment_type === 'bank') {
            return this.payWithBank();
        }
    }
}


export default MarketPlaceDetails
