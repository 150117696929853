import React, {useState, useEffect, useContext} from 'react';
import {Link} from "react-router-dom";
import API from "../../api";
import {hideLoader, mixpanel, showLoader, toast, trackMixpanel} from "../../_helpers/Loader";
// import $ from "jquery";
// import {Context} from "../MyContext";
import {PAYSTACK_publicKey} from "../../env";
import './style.css';

const QualificationNotification = (props) => {
  // const [state, setstate] = useState(initialState);
  const context = props.context;

  const cancelQualification = () => {
    window.Swal.fire({
      title: 'Are you sure you want to cancel application?',
      html: `<p>Canceling the application deletes the application from our system.</p>`,
      icon: 'info',
      confirmButtonText: 'Ok',
      confirmButtonColor: "#1F5277",
      showCloseButton: true
    }).then(async(result) => {
        if (result.isConfirmed){
          try {
            await API.post('users/delete_accreditation/', {});
            toast.success("Qualification has been cancelled successfully");
            await context.updateUser()
            setTimeout(() => {
              window.location.assign("/")
            }, 5000);
            hideLoader();
          } catch (err) {
              toast.error("Something went wrong");
              // console.log(err.response);
              hideLoader();
          }
        }
      }
    )
  }

  const payWithCard = () => {
    var handler = window.PaystackPop.setup({
        key: PAYSTACK_publicKey,
        email: context.user.email,
        amount: context.user.qualification_cost * 100 * context.user.display_usd,
        currency: "NGN",
        ref: "accreditation-" + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        metadata: {
            custom_fields: [
                {
                    display_name: "First Name",
                    variable_name: "firstname",
                    value: context.user.first_name,
                }, {
                    display_name: "Last Name",
                    variable_name: "lastname",
                    value: context.user.last_name,
                }, {
                    display_name: "Customer Number",
                    variable_name: "customer_phone",
                    value: context.user.phone,
                },
            ]
        },
        callback: (response) => {
            console.log(response, "google google")
            response.amount = context.user.qualification_cost * 100 * context.user.display_usd
            showLoader();
            if (
                response.status === "success"
            ) {
                // redirect to a success page
                API.post('users/update_accreditation_payment/', response).then((res) => {
                    context.updateUser();
                    hideLoader();
                    toast.success("Payment successfully completed");
                    setTimeout(() => {
                      window.location.reload()
                    }, 10000)
                }, function (err) {
                    hideLoader();
                    toast.error("Something Went Wrong")
                })

            } else {
                // redirect to a failure page.
                hideLoader();
                toast.error("Payment Error.")
            }
        },
        onClose: function () {
            toast.info("Payment Cancelled");
            hideLoader();
        }
      });
      handler.openIframe();
  };

  const displayDecline = (value) => {
    if (value === "Send_a_verification_request_to_my_lawyer"){
      return (<>
      <p>Document Submitted: Qualification Status Letter Request from Lawyer or SEC-registered Institution</p><br/><p>Your qualification application was declined. <b>The letter provided by your lawyer/SEC -registered institution did not satisfy our requirements to become a qualified investor on Twelve</b></p>
      </>)
    } else if (value === "Upload_a_verified_status_letter_from_a_registered_SEC_institution_or_Lawyer"){
      return (<>
        <p>Document Submitted: Qualification Status Letter Uploaded</p><br/><p>Your qualification application was declined. <b>The letter uploaded did not satisfy our requirements to become a qualified investor on Twelve</b></p>
        </>)
    } else if (value === "A_Professional_license_that_demonstrates_my_investment_knowledge"){
      return (<>
        <p>Document Submitted: Professional certificate uploaded</p><br/><p>Your qualification application was declined. <b>The professional certificate uploaded did not meet the requirements to become a qualified investor on Twelve.</b></p>
        </>)
    } else if (value === "connectBank"){
      return (<>
        <p>Document Submitted: Bank Statement</p><br/><p>Your qualification application was declined. <b>The financial information provided does not meet the financial prerequisites required to become a qualified investor on Twelve.</b></p>
        </>)
    }
  }

  return (
    <div className="row">
        <div className="col-md-12">
          {context.user.accredited === "YES" && context.user.accreditation_payment === true ? 
            (<div className="card">
                <div className="card-header">
                    <h4 className="fam float-left">Qualification Status: {'Completed and Payment confirmed'}</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <p>Your qualification application request was successful and your payment is now confirmed.</p>
                      <p><b>Congratulations! You are now a qualified investor on the Twelvevest platform.</b> You now have a verified badge on your dashboard. Look out for information in your inbox on how to take advantage of special offers for qualified investors on the platform.</p>
                      <br/>
                      <h5><b>Next Steps</b></h5>
                      {/* <i className="side-menu__icon fa fa-file-archive-o" style={{"color": "red!important"}} /> */}
                      { !context.user.kyc_verified ? (<p> <span  className="fam fa fa-square"></span> Please verify your identity in <Link to={"/profile"} className="twelve-blue" style={{"cursor": "pointer"}}><b>Settings</b></Link></p>) : "" }
                      {context.user.accounts.length > 0 ? "" : (<p><span  className="fam fa fa-square"></span> Set up your <Link to={"/account/wallet"} className="twelve-blue" style={{"cursor": "pointer"}}><b>Wallet</b></Link></p>)}
                      {["", null].includes(context.user.address) ? "" : (<p><span  className="fam fa fa-square"></span> Complete your <Link to={"/profile"} className="twelve-blue" style={{"cursor": "pointer"}}><b>Address</b></Link></p>)}
                    </div>
                  </div>
                </div>
            </div>) : ""}
          {/* approve and awaiting payment */}
          {context.user.accredited === "YES" && context.user.accreditation_payment === false ?
            (<div className="card">
                <div className="card-header">
                    <h4 className="fam float-left">Qualification Status: Completed and Awaiting payment</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <p><b>Your qualification application request was successful.</b> Your payment of $100 is now required to complete the qualification process. You will be able to view an update on your dashboard as soon as your payment is confirmed.</p><br/>
                      <p className="text-left"><button className="btn btn-primary" onClick={payWithCard}> Click here to make payment</button></p>
                      {/* {Watch out for communication on applicable opportunities} */}
                    </div>
                  </div>
                </div>
            </div>) : ""}

          {context.user.accredited === "PARTIALLY" && (context.user.accreditation_mail_request_response === 'No Submission' || context.user.accreditation_mail_request_response === 'True') ?
            (<div className="card">
                <div className="card-header">
                    <h4 className="fam float-left">Qualification Status: {'In-Progress'}</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <p>Documents Pending: None</p>
                      <p>We will notify you once verification is complete.</p><br/>
                      <p><div className="row"><div className="col-5">To cancel application or start over </div><div className="col-5"><button className="btn btn-primary" onClick={(e) => cancelQualification()}>Cancel Application</button></div></div></p>
                    </div>
                  </div>
                </div>
            </div>) : ""}

          {context.user.accredited === "PARTIALLY" && context.user.accreditation_mail_request_response === 'False'?
            (<div className="card">
                <div className="card-header">
                    <h4 className="fam float-left">Qualification Status: {'Awaiting required documentation'}</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <p>Documents Pending: Qualification Status Letter.</p><br/>
                      <p>We have not received your qualification status letter from <strong className='font-bold'><b>{context.user.accreditation_mail_address}</b></strong>. You can download a new qualification status letter <a href="/Verification-Status-Letter.pdf" target="_blank" download><b>here</b></a>, have it executed by your Lawyer or investment Advisor and upload below</p>
                      <u>Other Options:</u><br/>
                      <ul>
                        <li className='py-1'><div className="row"><div className="col-5">To upload an executed qualification status letter</div><div className="col-5"><button className="btn btn-primary p-1" data-toggle="modal" data-target="#uploadLetter">Upload Letter</button></div></div></li>
                        <li><div className="row"><div className="col-5">To cancel application or start over</div><div className="col-5"><button className="btn btn-primary p-1" onClick={(e) => cancelQualification()}>Cancel Application</button></div></div></li>
                      </ul>
                    </div>
                  </div>
                </div>
            </div>) : ""}

            {context.user.accredited === "DECLINED" && context.user.accreditation_score > 19 && context.user.accreditation_other_document_type !== "No Submission" ?
            (<div className="card">
                <div className="card-header">
                    <h4 className="fam float-left">Qualification Status: {'Declined'}</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12">
                      {displayDecline(context.user.accreditation_other_document_type)}
                      <p>You can reapply to get qualified in six months. If you have further questions, kindly contact the <a href="mailto:support@twelvevest.com">support@twelvevest.com</a></p>
                      {/* {Watch out for communication on applicable opportunities} */}
                    </div>
                  </div>
                </div>
            </div>) : ""}
        </div>
    </div>
  )
} 


export default QualificationNotification;
