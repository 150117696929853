// const BASE_URL = 'http://127.0.0.1:8000/api/v1/';

const BASE_URL =  "https://apisandbox.twelvevest.com/api/v1/";
const PAYSTACK_publicKey = "pk_test_707b132b0745ce619d373689f6a97a0baf6ec862";
const MONNIFY_APIKey = "MK_TEST_HP6NC9Y2W6";
const MONNIFY_contractCode = "5172522383";
const MIXPANEL_KEY = "2414a7691a8d506c2f0b693ef58b213b";



// const BASE_URL =  "https://api.twelvevest.com/api/v1/";
// const PAYSTACK_publicKey = "pk_live_4fe194816b72571adb931b53defa23b0e5ff8493";
// const MONNIFY_APIKey = "MK_PROD_HZ887RTLC7";
// const MONNIFY_contractCode = "442924854661";
// const MIXPANEL_KEY = "2414a7691a8d506c2f0b693ef58b213b"

const OKRA_PUBLIC_KEY = "37b2072a-0a04-55a4-9bce-bdc6e0c95b53";
const OKRA_TOKEN = "635a94aa1028cf04c1d0edcc";

export {BASE_URL, PAYSTACK_publicKey, MONNIFY_contractCode, MONNIFY_APIKey, MIXPANEL_KEY, OKRA_PUBLIC_KEY, OKRA_TOKEN}
